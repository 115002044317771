<template>
  <div :class="classList.sideMenu">
    <div :class="classList.sideMenuContainer">
      <component :is="MAIN_MENU_COMPONENT"></component>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import {
  Module,
  SHOWS_MAIN_MENU,
  MAIN_MENU_COMPONENT,
  SIDE_MENU,
  EMPTY_STRING,
} from '@/constants'

const CSN_SIDE_BAR = 'csn-side-bar'
const CSN_SIDE_MENU = 'csn-side-menu'
const CSN_SIDE_MENU_CONTENT = `${CSN_SIDE_MENU}-container`
const OPEN = 'open'

export default {
  name: SIDE_MENU,
  components: {
    MainMenu: () => import('./components/MainMenu'),
    MainMenuGame: () => import('./components/MainMenuGame'),
    MainMenuGameCategory: () => import('./components/MainMenuGameCategory'),
    MainMenuLanguage: () => import('./components/MainMenuLanguage'),
  },
  computed: {
    ...mapState(Module.MAIN, {
      SHOWS_MAIN_MENU,
      MAIN_MENU_COMPONENT,
    }),
    classList() {
      return {
        sideMenu: [
          this.SHOWS_MAIN_MENU ? OPEN : EMPTY_STRING,
          CSN_SIDE_MENU,
          CSN_SIDE_BAR,
        ],
        sideMenuContainer: CSN_SIDE_MENU_CONTENT,
      }
    },
  },
}
</script>
